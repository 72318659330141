<template>
  <a-modal v-model:visible="windowShow" width="90%" :title="detailTitle" footer="" :getContainer="false" destroyOnClose
    :bodyStyle="{ paddingTop: '0' }" @cancel="closeClear">
    <div>
      <a-form layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-cascader v-model:value="startCitys" :options="$store.state.app.cityDict" :show-search="{ filter }"
            :getPopupContainer="e => e.parentNode" :changeOnSelect="true" @change="setStartAddressMethod" placeholder="起点"
            allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-cascader style="width: 100%" v-model:value="endCitys" :options="$store.state.app.cityDict"
            :getPopupContainer="e => e.parentNode" :show-search="{ filter }" :changeOnSelect="true"
            @change="setEndAddressMethod" placeholder="终点" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-date-picker style="width: 100%" v-model:value="searchForm.actArrivalTimeStart"
            :getCalendarContainer="e => e.parentNode" @change="orderStart" placeholder="运车完成时间起" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-date-picker style="width: 100%" v-model:value="searchForm.actArrivalTimeEnd"
            :getCalendarContainer="e => e.parentNode" @change="orderEnd" placeholder="运车完成时间终" allowClear />
        </a-form-item>
        <a-form-item label="">
          <a-month-picker style="width: 100%" v-model:value="searchForm.arriveTime"
            :getCalendarContainer="e => e.parentNode" placeholder="运车完成时间" @change="arriveTimeChange"
            :allowClear="false" />
        </a-form-item>
        <a-form-item label="">
          <a-select style="width: 100%" v-model:value="searchForm.vehicleNum" allowClear
            :getPopupContainer="e => e.parentNode" placeholder="台量类型">
            <a-select-option :value="1">散车</a-select-option>
            <a-select-option :value="2">批量车</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select placeholder="业务类型" v-model:value="searchForm.transportDistanceEnum"
            :getPopupContainer="e => e.parentNode" style="width: 100%" allowClear>
            <a-select-option :value="1">短驳</a-select-option>
            <a-select-option :value="2">同省</a-select-option>
            <a-select-option :value="3">跨省</a-select-option>
            <a-select-option :value="4">跨国</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.orderId" placeholder="订单号" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.salesman" placeholder="业务员" />
        </a-form-item>
        <!-- <a-form-item label="">
          <a-select style="width: 100%" placeholder="业务部门" :getPopupContainer="e => e.parentNode" allowClear
            v-model:value="searchForm.superOrgId">
            <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id">{{ item.name
            }}</a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="">
          <a-space>
            <a-button :loading="loading" type="primary" @click="searchData">
              查询
            </a-button>
            <!--            <a-button @click="resetData"> 重置 </a-button>-->
          </a-space>
        </a-form-item>
      </a-form>
      <a-table :columns="[sortColumn, ...columns]" :row-key="(record) => record.id" :data-source="listData" bordered
        :loading="loading" size="small" :rowKey="(record, index) => { return index }" :pagination="pagination"
        @change="handleTableChange" :scroll="{ y: 500 }">
        <template #sort="{ index }">
          {{ index + 1 }}
        </template>
        <template #vehBrand="{ record }">
          <div>
            <a @click="lookDetail(record)">{{ record.vinNo }}&nbsp;{{ record.brand + record.model }}</a>
          </div>
        </template>
        <template #overText="{ record }">
          <div>
            <div v-for="(item, index) in record.overText" :key="index">{{ item }}</div>
          </div>
        </template>
      </a-table>
    </div>
  </a-modal>
  <a-modal v-model:visible="detailShow" footer="" :getContainer="false" width="65%"
    :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
    <template #title>
      <WindowDetail v-if="detailData.vinNo" :detailData="detailData" />
    </template>
    <div class="left-content" style="width: auto">
      <TransportModel ref="transportModelRef" :windowShow="detailShow" :data="detailData" :detailType="true"
        @statusShow="transportModelShowStatus" />
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, watch, ref, onMounted } from 'vue'
import { getTimeOutData } from '@/api/dataCenter/monitoring'
import WindowDetail from '@/views/components/vinNo/windowDetail.vue'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'

export default {
  props: {
    orgId: String,
    title: String,
    yearMonth: String,
    addressId: Object,
    startProvince: String,
    dispatchWay: Number,
    searchFormData: Object
  },
  components: {
    WindowDetail,
    TransportModel
  },
  setup (props) {
    const transportModelRef = ref(null)
    const state = reactive({
      detailShow: false,
      loading: false,
      windowShow: false,
      deliveryData: {},
      detailData: {},
      detailTitle: '',
      searchForm: {
        arriveTime: props.yearMonth,
        salesman: null,
        orderId: null,
        actArrivalTimeStart: null,
        actArrivalTimeEnd: null,
        dispatchWay: null,
        // superOrgId: props.orgId || null,
        transportDistanceEnum: null,
        startAddress: { city: null, province: null },
        endAddress: { city: null, province: null }
      },
      mirrorSearchForm: {},
      startCitys: [],
      endCitys: [],
      listData: [],
      sortColumn: { title: '序号', width: 50, slots: { customRender: 'sort' } },
      columns: [
        {
          title: '车牌/车型',
          dataIndex: 'vehBrand',
          slots: {
            customRender: 'vehBrand'
          }
        },
        {
          title: '订单号',
          align: 'center',
          dataIndex: 'orderId'
        },
        {
          title: '业务员',
          width: '5%',
          align: 'center',
          dataIndex: 'salesman'
        },
        {
          title: '起点',
          align: 'center',
          width: '10%',
          dataIndex: 'startDetails'
        },
        {
          title: '终点',
          align: 'center',
          width: '10%',
          dataIndex: 'endDetails'
        },
        {
          title: '下单时间',
          align: 'center',
          width: '8%',
          dataIndex: 'createTime'
        },
        {
          title: '运车完成时间',
          width: '8%',
          align: 'center',
          dataIndex: 'actArrivalTime'
        },
        {
          title: '台量类型',
          width: '7%',
          align: 'center',
          dataIndex: 'vehicleNum.label'
        },
        {
          title: '业务类型',
          width: '6%',
          align: 'center',
          dataIndex: 'transportDistance.label'
        },
        {
          title: '超时小时',
          width: '6%',
          align: 'center',
          dataIndex: 'overTime'
        },
        {
          title: '超时任务段',
          align: 'center',
          dataIndex: 'overText',
          slots: {
            customRender: 'overText'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`,
      }
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    watch(() => props.title, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        // closeClear()
        state.detailTitle = props.title + '——超时车辆'
      }
    })
    const setData = () => {
      state.searchForm.arriveTime = props.yearMonth
      if (props.addressId) {
        state.searchForm.startAddress.city = props.addressId.startCityId
        state.searchForm.startAddress.province = props.addressId.startProvinceId
        state.searchForm.endAddress.city = props.addressId.endCityId
        state.searchForm.endAddress.province = props.addressId.endProvinceId
        state.startCitys = [String(props.addressId.startProvinceId),String(props.addressId.startCityId)]
        state.endCitys = [String(props.addressId.endProvinceId),String(props.addressId.endCityId)]
      }
      state.searchForm.startAddress.provinceName = props.startProvince
      state.searchForm.superOrgId = props.orgId
      state.searchForm.dispatchWay = props.dispatchWay || props.searchFormData.dispatchWay
      state.searchForm.orderBusiness = props.searchFormData.orderBusiness
      state.searchForm.transportDistanceEnum = props.searchFormData.transportDistance
      state.searchForm.vehicleNum = props.searchFormData.vehicleNum
      state.searchForm.excludeTypes = props.searchFormData.excludeTypes
    }
    const loadData = () => {
      state.loading = true
      getTimeOutData({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then((res) => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const setStartAddressMethod = (e, v) => {
      if (e.length <= 0) state.searchForm.startAddress = {}
      else {
        state.searchForm.startAddress.province = e[0]
        state.searchForm.startAddress.provinceName = v[0].label
      }
      if (e.length >= 2) {
        state.searchForm.startAddress.city = e[1]
        state.searchForm.startAddress.cityName = v[1].label
        state.searchForm.startAddress.area = e[2]
        state.searchForm.startAddress.areaName = v[2].label
      }
    }
    const setEndAddressMethod = (e, v) => {
      if (e.length <= 0) state.searchForm.endAddress = {}
      else {
        state.searchForm.endAddress.province = e[0]
        state.searchForm.endAddress.provinceName = v[0].label
      }
      if (e.length >= 2) {
        state.searchForm.endAddress.city = e[1]
        state.searchForm.endAddress.cityName = v[1].label
        state.searchForm.endAddress.area = e[2]
        state.searchForm.endAddress.areaName = v[2].label
      }
    }
    const orderStart = (e, v) => {
      state.searchForm.actArrivalTimeStart = v
    }
    const orderEnd = (e, v) => {
      state.searchForm.actArrivalTimeEnd = v
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.startCitys = []
      state.endCitys = []
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      searchData()
    }
    const arriveTimeChange = (e, v) => {
      state.searchForm.arriveTime = v
    }
    const closeClear = () => {
      state.startCitys = []
      state.endCitys = []
      state.searchForm = JSON.parse(state.mirrorSearchForm)
    }
    const handleTableChange = (page) => {
      state.pagination = page
      loadData()
    }

    const lookDetail = (record) => {
      state.detailData = record
      state.detailShow = true
      // state.carDetailShow = true
      setTimeout(() => {
        transportModelRef.value.getTaskListData()
      }, 50)
    }
    const transportModelShowStatus = (e) => {
      if (e.orderId) state.detailData = e
    }
    return {
      ...toRefs(state),
      transportModelRef,
      setData,
      closeClear,
      loadData,
      searchData,
      resetData,
      orderStart,
      orderEnd,
      arriveTimeChange,
      handleTableChange,
      setStartAddressMethod,
      setEndAddressMethod,
      lookDetail,
      transportModelShowStatus
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  width: 18%;
  margin: 5px;
}
</style>
